<template>
   
    <PaypalButton :v-bind="loaded==true" :amount="cart.amount" variant="app" :cart="cart" />
           
</template>

<script>
import sendinblue  from 'sendinblue';
import fbq  from 'fbq';
import jQuery  from 'jquery';
import {Auth, APIRequest, conversionApi} from "@simb2s/senseye-sdk";
import emitter from 'tiny-emitter/instance'
import PaypalButton from '../components/elements/PaypalButton.vue'


export default {
  name: 'PaypalApp',
  components: {
   
    PaypalButton,
   
  },
  data() {
    return {
     
      cart:null,
      loaded:false
     
    }
  },
  watch:
  {
    
   
  },
  methods:{
    
   
    
      
  },
  async mounted()
  { 
    if(this.$route.params.id) {
        this.cart=await APIRequest.detail('carts',this.$route.params.id)
    }
    
    this.loaded=true
  }
  
}
</script>
<style  scoped>
.delivery-step
{
    display: block;
    margin-bottom:3px;
}
.delivery-steps 
{
    font-size:12px;
    text-align: left;
}
.delivery-steps .number
{
    background:#000;
    border-radius:50%;
    padding:5px 8px;
    display: inline-block;
    margin-right:1px;
    font-size:9px;
    color:#fff;
}
#popup-payment
{
    display:block;
}
.product-detail .subtotal
{
    float:right;
    clear:none;
    width:auto;
    display: inline-block;
}
.product-detail
{
    clear:both;
    display:block;
    width:100%;
}
</style>

